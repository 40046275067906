<template>
  <div>
      <!-- Import 'TheLoginForm' component as is, without parameters. -->
      <TheLoginForm />
  </div>
</template>

<script>
import {
  TheLoginForm
} from "@/components";

export default {
  name: "Login",
  components: {
    TheLoginForm
  }
};
</script>